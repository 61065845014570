import { getMediaFromData } from "~/data/media";

//
// #data object definitions
//
export class Seo {
  constructor({ title, description, robots, keywords, image, metaOg, metaTwitter }) {
    this.title = title;
    this.description = description;
    this.robots = robots;
    this.keywords = keywords;
    this.image = image;
    this.metaOg = metaOg;
    this.metaTwitter = metaTwitter;
  }
}

export class MetaOG {
  constructor({ title, description, image, type }) {
    this.title = title;
    this.description = description;
    this.image = image;
    this.type = type || 'website';
  }
}

export class MetaTwitter {
  constructor({ title, description, image, card }) {
    this.title = title;
    this.description = description;
    this.image = image;
    this.card = card || 'summary_large_image';
  }
}
//
// #data object factories
//
export function getSeoFromData(apiData) {
  const data = apiData;
  if (!data) { return null }

  const {
    metaTitle: title,
    metaDescription: description,
    metaRobots: robots,
    keywords,
  } = data;

  function getImageUrl(mediaData) {
    return getMediaFromData(mediaData)?.url;
  }

  const image = getImageUrl(data.metaImage);
  const metaOgData = data.metaSocial?.find((item) => item.socialNetwork === 'Facebook');
  const metaTwitterData = data.metaSocial?.find((item) => item.socialNetwork === 'Twitter');

  const metaOg = new MetaOG({
    title: metaOgData?.title || title,
    description: metaOgData?.description || description,
    image: getImageUrl(metaOgData?.image) || image,
  });

  const metaTwitter = new MetaTwitter({
    title: metaTwitterData?.title || title,
    description: metaTwitterData?.description || description,
    image: getImageUrl(metaTwitterData?.image) || image,
  });

  return new Seo({ title, description, robots, keywords, image, metaOg, metaTwitter });
}
