/* eslint-disable */
import { getSeoFromData } from '~/data/seo';

let defSeo = null;
let base = null;

function extractMeta(seo, baseUrl) {
    if (!seo) return;

    return {
        title: seo.title,
        description: seo.description,
        robots: seo.robots,
        keywords: seo.keywords,
        ogTitle: seo.metaOg.title,
        ogDescription: seo.metaOg.description,
        ogImage: baseUrl + seo.metaOg.image,
        ogType: seo.metaOg.type,
        twitterImage: baseUrl + seo.metaTwitter.image,
        twitterDescription: seo.metaTwitter.description,
        twitterCard: seo.metaTwitter.card,
        twitterTitle: seo.metaTwitter.title,
    }
}

export default async function (data) {
    const nuxtApp = useNuxtApp();

    if (!base) {
        const { public: pub } = useRuntimeConfig();
        base = pub.frontBase;
    }

    if (!defSeo) {
        const { data } = await nuxtApp.runWithContext(() => useAPIConfig());
        defSeo = data?.value?.seo;
    }

    const seo = data || getSeoFromData(defSeo);

    nuxtApp.runWithContext(() => useHead({ base }));
    if (!seo || Object.keys(seo).length <= 0) {
        console.error('ERROR seo data are not available');
        return;
    };

    const metas = extractMeta(seo, base);
    return nuxtApp.runWithContext(() => useSeoMeta(metas));
}