<template>
  <div
    :class="[
      'page-blocks',
      { 'page-blocks--iso-offset': containIsoTitle },
    ]"
  >
    <slot name="header" />

    <div v-if="debug" class="app-gutter app-width-large">
      <JsonViewer :json="content" />
    </div>

    <div class="page-blocks__content">
      <slot>
        <block-factory v-if="!error && content" :content="content" :type="type" />
        <p v-else class="app-gutter app-width-large" style="color:red;">
          {{ error }}
        </p>
      </slot>
    </div>

    <slot name="footer" />
  </div>
</template>

<script>
export default {
  props: {
    content: { type: [Object, Array], default: null },
    error: { type: [String, Object], default: null },
    debug: { type: Boolean, default: false },
    type: { type: String, default: null },
  },

  computed: {
    containIsoTitle() {
      const isoTitleComponents = ['headers.isometric-header', 'section.section'];
      const matchInList = (txt, list) => txt !== undefined ? list.indexOf(txt) > -1 : false;
      const matchIsoTitle = (comp) => matchInList(comp.__component, isoTitleComponents);

      if (!this.content) return false;
      if (this.content.__component && matchIsoTitle(this.content)) return false;
      if (!Array.isArray(this.content)) return false;

      return this.content.findIndex(item => matchIsoTitle(item)) > -1;
    },
  },
};
</script>

<style lang="stylus" scoped>
/* spacing horizontal */
:deep(>.header-iso)
:deep(>.title-arrow)
:deep(>.title-iso)
:deep(>.button-block)
:deep(>.expandable)
:deep(>.small-pictures)
:deep(>.richtext) {
  @extends .app-gutter-mobile-only
}

:deep(>.button-text)
:deep(>.header-centered) {
  @extends .app-gutter
}

:deep(>.small-pictures) {
  +size(L) {
    padding-left 126px
    padding-right @padding-left
  }
}

:deep(>.header-edito) {
  @extends .app-gutter-desktop-only
}

:deep(>.header-edito .header-centered) {
  @extends .app-gutter-mobile-only
}

:deep(>.small-pictures)
:deep(>.header-iso)
:deep(>.header-edito)
:deep(>.header-hero) {
  @extends .app-width-large
}

.page-blocks__content {
  :deep(>.title-arrow),
  :deep(>.richtext),
  :deep(>.expandable),
  :deep(>.button-block),
  :deep(>.collection .collection__filters) {
    @extends .app-width-default
  }

  :deep(>.collection .collection__filters.filters-combo) {
    @extends .app-gutter-desktop-only
    max-width none
  }

  :deep(>.collection-home)
  :deep(>.collection) {
    @extends .app-width-large
  }
}

.page-blocks--iso-offset .page-blocks__content {
  :deep(>.title-arrow),
  :deep(>.richtext),
  :deep(>.expandable),
  :deep(>.button-block),
  :deep(>.collection .collection__filters) {
    @extends .app-iso-margin, .app-width-small
  }
}

/* spacing vertical */
:deep(>.header-iso) {
  margin-top 50px
  margin-bottom 50px

  +size(L) {
    margin-bottom 90px
  }
}

:deep(>.header-edito .header-centered)
:deep(>.header-centered) {
  margin-top 50px
  margin-bottom @margin-top

  +size(L) {
    margin-top 80px
    margin-bottom 100px
  }
}

:deep(>.header-edito) {
  +size(L) {
    margin-bottom 75px
  }
}

:deep(>.header-hero) {
  margin-bottom 50px
}

:deep(>.quote)
:deep(>.button-block) {
  margin-top 50px
  margin-bottom @margin-top
}

:deep(>.title-arrow) {
  margin-top 100px
  margin-bottom 25px

  +size(L) {
    margin-bottom 50px
  }
}

:deep(>.collection-home) {
  margin-top 100px
}

:deep(>.title-arrow--hidden) {
  margin-top 50px
}

:deep(>.button-block) {
  margin-top 50px
  margin-bottom 70px
}

:deep(>.button-text) {
  margin-top 50px
  margin-bottom @margin-top
  +size(L) {
    margin-top 100px
    margin-bottom @margin-top
  }
}

:deep(>.player)
:deep(>.gallery) {
  margin-top 68px
  margin-bottom 100px
  +size(L) {
    margin-top 240px
    margin-bottom @margin-top
  }
}

:deep(>.small-pictures) {
  margin-top 100px
  margin-bottom @margin-top
}

:deep(>.json-viewer) {
  margin-top 50px
  margin-bottom @margin-top
}
</style>
