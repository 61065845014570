<template>
  <div class="json-viewer">
    <div v-if="json !== null">
      <!-- eslint-disable vue/no-v-html -->
      <code v-html="jsonHtml" />
    </div>

    <code v-else>Aucunes données 🤷‍♂️</code>
  </div>
</template>

<script>
export default {
  props: {
    json: { type: [Object, String], default: null },
    level: { type: Number, default: 5 },
  },

  computed: {
    jsonHtml() {
      if (!this.json) return null;

      const data = typeof (this.json) === 'string' ? JSON.parse(this.json) : this.json;
      const printLabel = (label) => `${label}: `;
      const printType = (type) => `<small><i>${type}</i></small>`;
      const printExpand = (summary, details, open = true) => `<details${open ? ' open' : ''}><summary>${summary}</summary>${details}</details>`;
      const printNumber = (num) => `<span style="color:blue">${num}</span>`;
      const printText = (text) => `<span style="color:red">"${text}"</span>`;
      const isAboveMaxLevel = ((maxLevel) => (level) => level > maxLevel)(this.level);

      const parseNode = function(node, label = '', level = 0) {
        let children, parsedChildren;
        if (!node) {
          return `<p>${printLabel(label)}<i>${printNumber('undefined')}</i></p>`;
        }

        switch (typeof(node)) {
          case 'object':
            if (Array.isArray(node)) {
              parsedChildren = node.map((value, index) => parseNode(value, index, level + 1)).join('');
              return printExpand(`${printLabel(label)}${printType('Array')}`, parsedChildren, !isAboveMaxLevel(level) && level >= 0);
            }

            children = Object.keys(node);
            parsedChildren = children.map(key => parseNode(node[key], key, level + 1)).join('');
            return printExpand(`${printLabel(label)}${printType('Object')}`, parsedChildren, !isAboveMaxLevel(level) && level >= 0);

          case 'number':
            return `<p>${printLabel(label)}${printNumber(node)}</p>`;

          case 'string':
            return `<p>${printLabel(label)}${printText(node)}</p>`;
        }

        return '<span>data !</span>';
      };

      return parseNode(data, 'root');
    },
  },
};
</script>

<style lang="stylus" scoped>
.json-viewer {
  padding 10px 20px
  background-color #fafafa
  color darkslategrey
  border-radius 5px
}

:deep(details) {
  padding-left 20px
}

:deep(summary),
:deep(p) {
  margin 0.5em 0
}

:deep(summary) {
  margin-left -20px
}
</style>
