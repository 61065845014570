import { Entry, getEntryFromData } from '~/data/entry';
import { getSeoFromData } from '~/data/seo';
import { toRaw } from 'vue';

//
// #data object definitions
//
export class Page extends Entry {
  constructor({ title, type, blocks, seo, ...entry }) {
    super(entry);
    this.title = title;
    this.type = type;
    this.blocks = blocks;
    this.seo = seo;
  }
}
//
// #data object factories
//
export function getPageFromData(apiData) {
  const data = apiData?.attributes;
  if (!data) { return null }

  const entry = getEntryFromData(apiData);
  const { title, contentType: type } = data;
  const seo = getSeoFromData(data.seo);
  const blocks = [];

  if (data.header) {
    blocks.push(...toRaw(data.header));
  }

  if (data.content) {
    blocks.push(...toRaw(data.content));
  }

  return new Page({ title, type, blocks, seo, ...entry });
}